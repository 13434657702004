
import { Component, Vue } from "vue-property-decorator";
import i18n from "../../plugins/i18n";
import ToastService from "../../services/helper/ToastService";

import AgePointCalculation from "../../models/sporteventCalculation/AgePointCalculation";
import AgeGenderGroupService from "../../services/sporteventCalculation/AgePointCalculationService";

@Component
export default class AgePointCalculationComponent extends Vue {
  public agePointCalculation: AgePointCalculation = new AgePointCalculation();

  mounted(): void {
    if (
      this.$route.params.id !== undefined &&
      this.$route.params.id !== "new"
    ) {
      AgeGenderGroupService.get(this.$route.params.id).then((item) => {
        this.agePointCalculation = item;
      });
    }
  }

  save(): void {
    AgeGenderGroupService.save(this.agePointCalculation).then(
      (item: AgePointCalculation) => {
        item = new AgePointCalculation(item); // otherwise you cannot use the functions
        ToastService.Success(i18n.tc("labels.saved"), item.name);
        if (item) {
          this.agePointCalculation = item;
          this.handleBack();
        }
      }
    );
  }

  handleBack(): void {
    this.$router.back();
  }
}
